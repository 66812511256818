import { ClientMessage, UserInfo, getUserAddress } from '@wheelstrategytools/messages'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import Stripe from 'stripe'
import { ClientWebSocketInterface } from '../../components/websockets/clientwebsocket'
import { useUser } from '../../pages/trade'
import { ClientIdContext } from '../utilities/client-id-provider'
import { StageContext, UberStage, uberStageFromString } from '../utilities/stage-provider'

const STRIPE_KEY_TEST = 'pk_test_51Mwc0dBbSUDUVJJ4LQIFKE224ajzB4MNPP0s0oAeuCdSbIpgjZgOk2gpXibckzCQeJWvN5KYdaRu4zv45rpfXQZm00hWUZlSBo'
const PRICING_TABLE_ID_TEST = 'prctbl_1NGTX7BbSUDUVJJ4DV0DC4DY'
export const BILLING_PORTAL_URL_TEST = 'https://billing.stripe.com/p/login/test_fZeaHa1MF3Ly0zC7st'

const STRIPE_KEY_PROD = 'pk_test_51Mwc0dBbSUDUVJJ4LQIFKE224ajzB4MNPP0s0oAeuCdSbIpgjZgOk2gpXibckzCQeJWvN5KYdaRu4zv45rpfXQZm00hWUZlSBo'
const PRICING_TABLE_ID_PROD = 'prctbl_1NGTX7BbSUDUVJJ4DV0DC4DY'
export const BILLING_PORTAL_URL_PROD = 'https://billing.stripe.com/p/login/test_fZeaHa1MF3Ly0zC7st'

export const UpgradeToMembership = () => {
    const clientId = React.useContext(ClientIdContext)
    const uberStage = uberStageFromString(React.useContext(StageContext))
    const user = useUser(clientId, uberStage)
    const router = useRouter()

    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)
    const [billingPortalUrl, setBillingPortalUrl] = useState<string>(BILLING_PORTAL_URL_PROD)
    const [pricingTableId, setPricingTableId] = useState<string>(PRICING_TABLE_ID_PROD)
    const [stripeKey, setStripeKey] = useState<string>(STRIPE_KEY_PROD)

    useEffect(() => {
        async function getUserInfo() {
            const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)
            await clientWebSocketInterface.sendMsgAuthenticatedWithPromise(
                new ClientMessage(getUserAddress, [], clientId)
            )
        }

        // getUserInfo()
        setUserInfo(user.data)
    }, [user.data])

    useEffect(() =>{
        if (uberStage === UberStage.DEVEL){
            setBillingPortalUrl(BILLING_PORTAL_URL_TEST)
            setPricingTableId(PRICING_TABLE_ID_TEST)
            setStripeKey(STRIPE_KEY_TEST)
        }else{
            setBillingPortalUrl(BILLING_PORTAL_URL_PROD)
            setPricingTableId(PRICING_TABLE_ID_PROD)
            setStripeKey(STRIPE_KEY_PROD)
        }
    },[uberStage])

    async function initiateStripeSession(priceId: string) {
        const stripe = new Stripe(stripeKey, {
            apiVersion: '2023-08-16'
        })

        if (userInfo) {
            const session = await stripe.checkout.sessions.create({
                customer_email: userInfo.userEmail,
                mode: 'subscription',
                line_items: [
                    {
                        price: priceId,
                        quantity: 1
                    }
                ],
                success_url: `${window.location.origin}/payments?status=success&session_id={CHECKOUT_SESSION_ID}`,
                cancel_url: `${window.location.origin}/payments?status=cancelled`
            })

            const redirectUrl = session.url
            if (redirectUrl !== null) {
                router.push(new URL(redirectUrl))
            }
        } else {
            console.log('Cannot initiate session because user info not available')
        }
    }

    async function initiateMonthlySubscriptionPurchase(): Promise<void> {
        await initiateStripeSession('price_1MxcrtBbSUDUVJJ4YO9WyOlW')
    }

    return (
        <React.Fragment>
            <Script async src="https://js.stripe.com/v3/pricing-table.js" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-9">

                        <h3><Link href="/">wheelstrategytools.com</Link> offers three levels levels of access:</h3>
                        <ul className="nobullets">
                            <li>
                                - <i><b>Guest access</b></i> is free and lets you read articles and learn about the site without establishing a login.
                            </li>
                            <li>
                                - <i><b>Registered access</b></i> is free, requires establishing a login and gives you Guest access plus the ability to run historical simulations.
                            </li>
                            <li>
                                - <i><b>Member access</b></i> is paid and gives full access to the site, including real time suggestions and tools for portfolio tracking.
                            </li>
                        </ul>
                        <p>This (the Trade) section of the site is available only to members.  The Trade section allows a member to get
                            suggestions about the available Wheel trades that will give the best annualized return.  If you&apos;re unsure
                            whether the fee is worth it or not, create a few <Link href="/simulate">simulations</Link> to get
                            an idea of what level of income would have been generated in the past.  Past returns are no guarantee of future
                            results.  For help in getting started, please peruse our <Link href="/articles">articles.</Link>
                        </p>
                        <p>
                            If you&apos;re ready to trade, please subscribe below.  You can cancel at any time.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {
                        user.isLoading ?
                            <Spinner key='spinner' color="success">Loading...</Spinner>
                            :
                            React.createElement('stripe-pricing-table', {
                                'pricing-table-id': pricingTableId,
                                'publishable-key': stripeKey,
                                'client-reference-id': user.data!.userId,
                                'customer-email': user.data!.userEmail
                            })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default UpgradeToMembership
