import Link from 'next/link';
import { NavBar } from './navigation/NavBar';
import CookieConsent, { Cookies } from "react-cookie-consent";

export const TopLevelLayout = ({ children }: { children: any }) => {

    return (
        <>
            <NavBar />
            <CookieConsent
                enableDeclineButton
                flipButtons
                buttonText="Accept All"
                declineButtonText="Use Only Essential Cookies"
                buttonStyle={{background:"green"}}
                declineButtonStyle={{background:"green"}}>
                This website requires a cookie to manage login. With your consent, it may also use cookies to
                track user activity in order to analyze and improve the user experience or manage
                advertisements or affiliate offers.  You may wish to review the <Link className="link" href="/termsofservice">terms of service </Link>
                    and the <Link className="link" href="/privacypolicy">privacy policy.</Link>
            </CookieConsent>
            <main>{children}</main>
            <div className="d-flex justify-content-center">
                <p>&copy;2023,2024 Wheel Strategy Tools, LLC</p>
            </div>
            <div className="d-flex justify-content-center">
                <p>By use of this site you consent to the <Link className="link" href="/termsofservice">terms of service </Link>
                    and the <Link className="link" href="/privacypolicy">privacy policy.</Link></p>
            </div>
        </>
    )
}

export default TopLevelLayout