//import { useSearchParams } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export enum UberStage {
    DEVEL = 'devel',
    PROD = 'prod'
}

export function uberStageFromString(uberStageAsString: string): UberStage {
    if (uberStageAsString === UberStage.PROD) {
        return UberStage.PROD
    } else {
        return UberStage.DEVEL
    }
}

// Provides information about whether this is a dev or prod stage.
export const StageContext = React.createContext<string>('')

const StageProvider = ({ children }: { children: React.ReactNode }) => {
    // const params = useSearchParams()
    const router = useRouter()
    const [uberStage, setUberStage] = useState<UberStage>(UberStage.PROD)

    useEffect(() => {
        if (router.isReady) {
            if ("uberstage" in router.query){
                if (router.query.uberstage === UberStage.PROD){
                    setUberStage(UberStage.PROD)
                }else{
                    setUberStage(UberStage.DEVEL) 
                }
            }
        }
    }, [router.isReady, router.query])

    return (
        <StageContext.Provider value={uberStage}>
            {children}
        </StageContext.Provider >
    )
}

export default StageProvider